<template>
    <svg width="69" height="53" viewBox="0 0 69 53" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.399414 14.7748L15.9994 0.799805V14.7748H0.399414Z" fill="white"/>
        <path d="M15.9994 52.3994H1.59941C0.936672 52.3994 0.399414 51.8622 0.399414 51.1994V38.9619H15.9994V52.3994Z"
              fill="white"/>
        <path d="M68.1994 9.51942V43.7155C68.1994 44.6723 67.1343 45.2443 66.3366 44.7158L54.9994 37.2044L39.3994 26.8687L54.9994 16.2419L66.3238 8.52767C67.1204 7.98501 68.1994 8.55555 68.1994 9.51942Z"
              fill="white" fill-opacity="0.6"/>
        <path d="M54.9994 16.2412L39.3994 26.868L54.9994 37.2037V16.2412Z" fill="white" fill-opacity="0.6"/>
        <path d="M53.799 52.3994H15.999V38.9619H39.399V26.8682L54.999 37.2038V51.1994C54.999 51.8622 54.4618 52.3994 53.799 52.3994Z"
              fill="white"/>
        <path d="M15.9994 14.7744H0.399414V38.9619H15.9994V14.7744Z" fill="white" fill-opacity="0.8"/>
        <path d="M15.999 0.799805H53.799C54.4618 0.799805 54.999 1.33706 54.999 1.9998V16.2417L39.399 26.8686V14.7748H15.999V0.799805Z"
              fill="white"/>
    </svg>

</template>

<script>
export default {
    name: "cameraIcon"
}
</script>

<style>

</style>
