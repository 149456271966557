<template>
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1949_1478)">
            <circle opacity="0.2" cx="8" cy="7.5" r="7" stroke="black"/>
            <circle v-if="complete" cx="8" cy="7.5" r="5" fill="black"/>
        </g>
        <defs>
            <clipPath id="clip0_1949_1478">
                <rect width="15" height="15" fill="white" transform="translate(0.5)"/>
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    props: {
        complete: false
    },
    name: "ScheduleState"
}
</script>

<style>

</style>
