<template>
  <div class="appointmentCard">
    <div class="appointmentCard__leftContainer">
      <div class="appointmentCard__title">
        {{ appointmentValue.service_name }}
      </div>
      <div class="appointmentCard__information">
        <div class="appointmentCard__type">
          {{ appointmentValue.consultation_name }}
        </div>
        <div class="appointmentCard__code">
          {{ appointmentValue.code }}
        </div>
      </div>
      <div class="appointmentCard__duration">
        {{ $t("duration") }}
        <duration-time :data="appointmentValue.consultation_duration" />
      </div>
      <div class="appointmentCard__expertInformation">
        <div>
          <div class="d-flex align-items-center mb-30">
            <div class="appointmentCard__image">
              <avatar
                v-if="loginUser.role_id === 4"
                width="80px"
                height="80px"
                user-role="experts"
                size="100"
                :user-code="appointmentValue.expert_code"
                :image-name="appointmentValue.expert_image"
              />
              <avatar
                v-if="loginUser.role_id === 2"
                width="80px"
                height="80px"
                user-role="employees"
                size="100"
                :user-code="appointmentValue.employee_code"
                :image-name="appointmentValue.employee_image"
              />
            </div>
            <div>
              <div class="appointmentCard__name">
                <short-name
                  v-if="loginUser.role_id === 4"
                  :name="
                    appointmentValue.expert_first_name +
                    ' ' +
                    appointmentValue.expert_last_name
                  "
                />
                <short-name
                  v-if="loginUser.role_id === 2"
                  :name="
                    appointmentValue.employee_first_name +
                    ' ' +
                    appointmentValue.employee_last_name
                  "
                />
              </div>
              <div
                v-if="loginUser.role_id === 4"
                class="appointmentCard__position"
              >
                {{ appointmentValue.expert_position }}
              </div>
              <div
                v-if="loginUser.role_id === 2"
                class="appointmentCard__position"
              >
                {{ appointmentValue.employee_code }}
              </div>
            </div>
          </div>
          <div class="appointmentCard__link" @click="goToExpertChat()">
            {{
              loginUser.role_id === 4
                ? $t("appointmentPageSendMessage")
                : $t("appointmentPageSendMessageExpert")
            }}
          </div>
        </div>
        <div class="appointmentCard__appointmentHistory">
          <p>{{ $t("appointmentHistory") }}</p>
          <div class="appointmentCard__appointmentHistory-scroll">
            <div
              v-for="(stage, index) in appointmentValue.stages"
              class="d-flex"
            >
              <complete-state
                v-if="index !== appointmentValue.stages.length - 1"
              />
              <schedule-state :complete="!appointmentFinished" v-else />
              <div class="appointmentCard__appointmentState">
                <div class="appointmentCard__appointmentStateName">
                  <div
                    v-if="
                      stage.action_name === 'canceled by employee' ||
                      stage.action_name === 'canceled by expert'
                    "
                  >
                    {{ $t("canceledBy") }}
                    <short-name
                      v-if="stage.action_name === 'canceled by employee'"
                      :name="`${appointmentValue.employee_first_name} ${appointmentValue.employee_last_name}`"
                    />
                    <short-name
                      v-if="stage.action_name === 'canceled by expert'"
                      :name="`${appointmentValue.expert_first_name} ${appointmentValue.expert_last_name}`"
                    />
                  </div>
                  <div v-else>
                    {{ stage.action_name }}
                  </div>
                </div>
                <div class="appointmentCard__appointmentStateData">
                  <data-formatted
                    date-format="EEEE, dd MMM, HH:mm"
                    :data="new Date(stage.time)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="appointmentCard__rightContainer"
      :class="{ 'justify-content-start': !appointmentFinished }"
    >
      <div
        class="appointmentCard__dataInformation"
        :class="{ 'mb-25': !appointmentFinished }"
      >
        <camera-icon />
        <div class="appointmentCard__dataContainer">
          <div class="appointmentCard__data">
            <data-formatted date-format="EEEE," :data="appointmentValue.time" />
          </div>
          <div class="appointmentCard__dataDay">
            <data-formatted
              date-format="dd MMM yyyy"
              :data="appointmentValue.time"
            />
          </div>
          <div class="appointmentCard__dataTime">
            <data-formatted date-format="HH:mm" :data="appointmentValue.time" />
          </div>
        </div>
      </div>
      <div class="appointmentCard__informationDescription">
        <div v-if="appointmentFinished">
          <information-icon />
        </div>
        <div>
          {{
            appointmentFinished
              ? $t("appointmentTenMinuteNotification")
              : `${$t("ThisAppointmentCanceled")} ${
                  appointmentValue.status_name
                }`
          }}
        </div>
      </div>
      <div class="text-center">
        <div v-if="appointmentFinished">
          <base-button
            v-if="rescheduleRules"
            @click="rescheduleAppointment()"
            class="appointmentCard__button"
            margin-left="0"
          >
            {{ $t("appointmentPageReschedule") }}
          </base-button>
          <base-button
            v-else
            :link="
              !rescheduleButtonState
                ? ''
                : `/appointment/${this.$route.params.appointment}/call`
            "
            class="appointmentCard__button"
            :class="{
              'appointmentCard__button-disabled': !rescheduleButtonState,
            }"
            margin-left="0"
          >
            {{ $t("joinAppointment") }}
          </base-button>
          <div
            v-if="cancelAppointmentBtn"
            @click="cancelAppointment()"
            class="appointmentCard__cancel"
          >
            {{ $t("appointmentPageCancel") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";
import ShortName from "@/components/components-helpers/ShortName";
import CameraIcon from "@/components/UI/icons/buttonIcons/CameraIcon.vue";
import BaseButton from "@/components/UI/buttons/BaseButton";
import DurationTime from "@/components/components-helpers/DurationTime";
import DataDay from "@/components/components-helpers/DataDay";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import InformationIcon from "@/components/UI/icons/buttonIcons/InformationIcon.vue";
import CompleteState from "@/components/UI/icons/historyState/CompleteState";
import ScheduleState from "@/components/UI/icons/historyState/ScheduleState";
import { mapGetters } from "vuex";
import { intervalToDuration } from "date-fns";

export default {
  props: {
    appointment: Object,
  },
  name: "AppointmentCard",
  components: {
    ScheduleState,
    CompleteState,
    InformationIcon,
    DataFormatted,
    DataDay,
    DurationTime,
    BaseButton,
    CameraIcon,
    ShortName,
    Avatar,
  },
  data() {
    return {
      appointmentValue: {
        code: "",
        consultation: {
          duration: 0,
          expert: {
            image: "expert1.png",
            first_name: "",
            last_name: "",
          },
        },
        time: new Date().toISOString(),
      },
      rescheduleRules: false,
      rescheduleButtonState: false,
      cancelAppointmentBtn: true,
      appointmentFinished: true,
    };
  },
  methods: {
    setRescheduleRules() {
      if (this.appointment.status_id === 2) {
        this.rescheduleRules = false;
        this.rescheduleButtonState = true;
        this.cancelAppointmentBtn = false;
        return;
      } else if (
        this.appointment.status_id === 3 ||
        this.appointment.status_name === 5
      ) {
        this.appointmentFinished = false;
        return;
      } else if (
        new Date(this.appointmentValue.time).getTime() < new Date().getTime()
      ) {
        this.rescheduleButtonState = true;
        this.cancelAppointmentBtn = false;
        return;
      }
      const timeToAppointment = intervalToDuration({
        start: new Date(),
        end: new Date(this.appointmentValue.time),
      });
      if (this.loginUser.role_id === 4) {
        this.rescheduleRules = timeToAppointment.days >= 1;
        this.cancelAppointmentBtn = timeToAppointment.days >= 1;
        this.rescheduleButtonState =
          timeToAppointment.hours === 0 && timeToAppointment.minutes <= 10;
      }
      if (
        this.loginUser.role_id === 2 &&
        timeToAppointment.hours === 0 &&
        timeToAppointment.hours === 0 &&
        timeToAppointment.minutes <= 10
      ) {
        this.rescheduleButtonState = true;
        this.cancelAppointmentBtn = false;
      }
    },
    rescheduleAppointment() {
      const appointmentInformation = {
        language: {
          code: this.appointment.language_code,
          id: this.appointment.language_id,
          name: this.appointment.language_name,
        },
        services: {
          id: this.appointment.service_id,
          name: this.appointment.service_name,
        },
        expert: {
          first_name: this.appointment.expert_first_name,
          last_name: this.appointment.expert_last_name,
          image: this.appointment.expert_image,
          user_id: this.appointment.expert_id,
          translations: [{ position: this.appointment.expert_position }],
        },
        type: {
          duration: this.appointment.consultation_duration,
          id: this.appointment.consultation_id,
          price: this.appointment.consultation_price,
          translations: [{ name: this.appointment.consultation_name }],
        },
        appointment_id: this.appointment.id,
      };
      this.$store.commit("SET_BOOK_DATA", appointmentInformation);
      this.$store.commit("SET_RESCHEDULE", true);
      this.$router.push("/calendar");
    },
    cancelAppointment() {
      let state = this.$store.getters.CANCEL_APPOINTMENT;
      this.$store.commit("SET_CANCEL_APPOINTMENT", !state);
      this.$store.commit("SET_APPOINTMENT_ID", this.appointment.id);
    },
    async goToExpertChat() {
      let chatId;
      if (this.loginUser.role_id === 4) {
        chatId = await this.$http
          .get(
            `/chats/expert/${this.appointmentValue.expert_id}/employee/${this.loginUser.id}`
          )
          .then(({ data }) => {
            return data.body.id;
          });
      } else if (this.loginUser.role_id === 2) {
        chatId = await this.$http
          .get(
            `/chats/expert/${this.loginUser.id}/employee/${this.appointmentValue.employee_id}`
          )
          .then(({ data }) => {
            return data.body.id;
          });
      }
      this.$store.commit("SET_OPEN_CHAT", chatId);
      this.$router.push("/chats");
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
  watch: {
    appointment: {
      deep: true,
      handler(value) {
        this.appointmentValue = value;
        setTimeout(() => {
          this.setRescheduleRules();
        }, 500);
      },
    },
  },
};
</script>

<style></style>
