<template>
  <main-layout>
    <div class="w-min-content">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
    </div>
    <appointment-card ref="appointmentCard" :appointment="appointment" />
    <h2>{{ $t("appointmentPageTitle") }}</h2>
    <div class="appointmentPage__information">
      <p>{{ $t("appointmentPageFirstText") }}</p>
      <p>{{ $t("appointmentPageSecondText") }}</p>
    </div>
    <div class="appointmentPage__accordion">
      <vue-collapsible-panel-group base-color="#ffffff" accordion>
        <vue-collapsible-panel :expanded="false">
          <template #title>
            {{ $t("appointmentPageAccordionPrivacy") }}
          </template>
          <template #content>
            {{ $t("appointmentPageAccordionPrivacyText") }}
          </template>
        </vue-collapsible-panel>
        <vue-collapsible-panel :expanded="false">
          <template #title>
            {{ $t("appointmentPageAccordionEthics") }}
          </template>
          <template #content>
            {{ $t("appointmentPageAccordionEthicsText") }}
          </template>
        </vue-collapsible-panel>
        <vue-collapsible-panel :expanded="false">
          <template #title>
            {{ $t("appointmentPageAccordionProfessionalism") }}
          </template>
          <template #content>
            {{ $t("appointmentPageAccordionProfessionalismText") }}
          </template>
        </vue-collapsible-panel>
      </vue-collapsible-panel-group>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import BaseButton from "@/components/UI/buttons/BaseButton";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import AppointmentCard from "@/components/elements/appointment/AppointmentCard";
import "@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css";
import {
  VueCollapsiblePanel,
  VueCollapsiblePanelGroup,
} from "@dafcoe/vue-collapsible-panel";

export default {
  name: "AppointmentPage",
  components: {
    AppointmentCard,
    HorizontalArrow,
    BaseButton,
    MainLayout,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
  },
  data() {
    return {
      appointment: {},
    };
  },
  async mounted() {
    this.appointment = await this.getAppointmentInformation();
    if (this.$route.query.cancel === "true") {
      setTimeout(() => {
        this.$refs.appointmentCard.cancelAppointment();
      }, 1000);
    }
  },
  methods: {
    async getAppointmentInformation() {
      return this.$http
        .get(`/appointments/code/${this.$route.params.appointment}`)
        .then(({ data }) => {
          return data.body;
        });
    },
  },
  watch: {
    $route: {
      deep: true,
      async handler() {
        this.appointment = await this.getAppointmentInformation();
      },
    },
  },
};
</script>

<style></style>
