<template>
    <svg width="16" height="51" viewBox="0 0 16 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line opacity="0.2" x1="8" y1="15" x2="8" y2="51" stroke="black"/>
        <g clip-path="url(#clip0_0_1)">
            <circle cx="8" cy="7.5" r="7" stroke="black"/>
            <circle cx="8" cy="7.5" r="5" fill="black"/>
        </g>
        <defs>
            <clipPath id="clip0_0_1">
                <rect width="15" height="15" fill="white" transform="translate(0.5)"/>
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "CompleteState"
}
</script>

<style>

</style>
